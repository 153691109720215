import React from 'react'
import About from './About'
import ChooseUs from './ChooseUs'
import Homeslideshow from './Homeslideshow'
import Services from './Services'


const Home = () => {
  return (
  
    <>
    <Homeslideshow/>
    <Services/>
    <ChooseUs/>
    <About/>
   
   

    
    </>
      
  )
}

export default Home